import React from 'react';
import { graphql } from 'gatsby';
import LoginButton from 'components/Login';

import '@react-website-themes/classy-docs/styles/variables';
import '@react-website-themes/classy-docs/styles/global';

import Helmet from 'react-helmet';
import Article from '@react-website-themes/classy-docs/components/Article';
import Branding from '@react-website-themes/classy-docs/components/Branding';
import Footer from '@react-website-themes/classy-docs/components/Footer';
import Header from '@react-website-themes/classy-docs/components/Header';
import Heading from '@react-website-themes/classy-docs/components/Heading';
import Layout from '@react-website-themes/classy-docs/components/Layout';
import List from 'components/List';
import Hero from '@react-website-themes/classy-docs/components/Hero';
import Menu from '@react-website-themes/classy-docs/components/Menu';
import LogoutButton from 'components/Logout';
import { css } from 'emotion';

import config from 'meta/config';
import menuItems from 'meta/menu';
import categoryList from 'meta/categories';
import Logo from 'assets/images/logo.png';
import { isAuthenticated } from '../Auth/AuthService';

const HideCredits = css`
  .credits {
    display: none;
  }
`;

class ContentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
    };
  }

  componentDidMount() {
    this.setAuthentication();
  }

  setAuthentication() {
    this.setState({ authenticated: isAuthenticated() });
  }

  render() {
    const {
      location: {
        pathname,
      },
      data: {
        pages: {
          edges: rawPages,
        },
        hero: {
          edges: heroEdges,
        },
        footerLinks: {
          edges: footerLinksEdges,
        },
        copyright: {
          edges: copyrightEdges,
        },
      },
    } = this.props;

    const {
      node: {
        body: {
          childMarkdownRemark: {
            html: footerLinksHTML,
          },
        },
      },
    } = footerLinksEdges[0];

    const {
      node: {
        body: {
          childMarkdownRemark: {
            html: copyrightHTML,
          },
        },
      },
    } = copyrightEdges[0];

    const {
      node: {
        body: {
          childMarkdownRemark: {
            html: heroHTML,
          },
        },
      },
    } = heroEdges[0];

    const {
      headerTitle,
      headerSubTitle,
    } = config;

    const pages = rawPages.map(page => page.node);
    const { authenticated } = this.state;

    return (
      <Layout>
        <Helmet title="Table of Contents | Able Policies" />
        <Header>
          <Branding title={headerTitle} subTitle={headerSubTitle} />
          <Menu items={menuItems} />
          {' '}
          {authenticated && (
            <LogoutButton
              setAuthentication={this.setAuthentication}
              pathname={pathname}
            />)
          }
        </Header>
        {
          !authenticated && (
            <Hero>
              <img src={Logo} className="image" alt="site logo" />
              <div dangerouslySetInnerHTML={{
                __html: heroHTML,
              }}
              />
              <LoginButton setAuthentication={this.setAuthentication} pathname={pathname} />
            </Hero>
          )
      }
        {
        authenticated && (
        <Article>
          <Heading title="Table of contents" />
          <List pages={pages} categoryList={categoryList} />
        </Article>
        )
      }
        <Footer customStyle={HideCredits} links={footerLinksHTML} copyright={copyrightHTML} />
      </Layout>
    );
  }
}

export default ContentPage;

export const query = graphql`
  query {
    pages: allContentfulPolicy {
      edges {
        node {
          id
          title
          date
          category
          slug
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    hero: allContentfulParts(
      filter: {
            title: { eq: "hero"}
          }) {
      edges {
        node {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    footerLinks: allContentfulParts(
      filter: {
            title: { eq: "footerLinks"}
          }) {
      edges {
        node {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    copyright: allContentfulParts(
      filter: {
            title: { eq: "copyrightNote"}
          }) {
      edges {
        node {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
