/* eslint-disable */
import React from 'react';
import {Link} from 'gatsby';
import {cx} from 'emotion';

import style from './ListStyle';

function organizePagesInCategories(pages, categoryList) {
  let catArray = [];
  categoryList.forEach(cat => {
    var tempArray = [];
    pages.forEach(page => {
      if (page.category === cat.name) {
        tempArray.push(page);
      }
    });
    tempArray.sort(
      (a, b) => (a.title > b.title)
      ? 1
      : (
        (b.title > a.title)
        ? -1
        : 0))
      if (tempArray.length > 0) {
        catArray.push(tempArray);
      }
  });
  return catArray;
}

const List = props => {
  const {
    pages,
    categoryList,
    themeStyle = style,
    customStyle = ''
  } = props;
  const pagesInCategories = organizePagesInCategories(pages, categoryList);

  return (<div className={cx(themeStyle, customStyle)}>
    {
      pagesInCategories.map(pages => {
        var label,
          Icon;

        categoryList.forEach(index => {
          if (index.name === pages[0].category) {
            Icon = index.icon;
            label = index.label;
          }
        })

        return (<React.Fragment key={label}>
          <h3>
            <Icon/> {label}
          </h3>
          <ul>
            {
              pages.map(page => {
                const {title, slug} = page;
                return (<li key={slug}>
                  <Link key={slug} to={slug}>
                    {title}
                  </Link>
                </li>);
              })
            }
          </ul>
        </React.Fragment>);
      })
    }
  </div>);
};

export default List;
